<template>
  <div class="my-order-list">
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      :error.sync="error"
      error-text="请求失败，点击重新加载"
      :immediate-check="false"
      @load="getList"
    >
      <order-card v-for="(item, index) in list" :key="'' + index" :detail="item" />
    </van-list>
  </div>
</template>

<script>
import orderCard from './components/orderCard.vue';
import {
  getMyOrderListUrl,
  userInfoURL
} from './api.js';
import Vue from 'vue';
import { mapState } from "vuex";
import { List } from 'vant';

Vue.use(List);

export default {
  name: 'eldCanMyOrderList',
  components: {
    orderCard
  },
  data() {
    return {
      loading: false,
      finished: false,
      error: false,
      list: [],
      total: 0,
      curPage: 1,
      params: {
        pageSize: 10,
        userId: '',
        tenantId: ''
      }
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  watch: {},
  created() {
    this.getList();
  },
  methods: {
    getList() {
      const params = {
        curPage: this.curPage,
        pageSize: 10,
        userId: this.userId,
        tenantId: this.tenantId,
      }
      this.$axios.get(getMyOrderListUrl, { params }).then(res => {
        if (res && res.code === 200) {
          res.data = res.data || {};
          res.data.records = Array.isArray(res.data.records) ? res.data.records : [];
          this.list.push(...res.data.records);
          this.curPage++;
          this.total = res.data.total;
          this.loading = false;
          if (this.list.length >= this.total) {
            this.finished = true;
          }
        } else {
          this.loading = false;
          this.error = true;
        }
      }).catch(() => {
        this.loading = false;
        this.error = true;
      });
    }
  }
};
</script>

<style lang="less" scoped>
.my-order-list {
  box-sizing: border-box;
  height: 100%;
  height: calc(100% - constant(safe-area-inset-bottom));
  height: calc(100% - env(safe-area-inset-bottom));
  overflow-x: hidden;
  overflow-y: auto;
  padding: 30px 30px;
  .order-card {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
}
</style>
