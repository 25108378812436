<template>
  <div class="order-card" @click="cardOnClick">
    <div class="card-title">订单编号：{{ detail.orderId }}</div>
    <div class="card-content">
      <div class="label-value-list">
        <div class="label-value-item">
          <div class="label-icon dcr"></div>
          <div class="label-text">订餐人：</div>
          <div class="value-text">{{ detail.userName }}</div>
        </div>
        <div class="label-value-item">
          <div class="label-icon scdz"></div>
          <div class="label-text">送餐地址：</div>
          <div class="value-text">{{ detail.houseAddress }}</div>
        </div>
        <div class="label-value-item">
          <div class="label-icon pssj"></div>
          <div class="label-text">下单时间：</div>
          <div class="value-text">{{ detail.orderTime }}</div>
        </div>
      </div>
      <div class="price-quantity-info">
        <div class="price-text">¥ {{ detail.orderAmount }}</div>
        <div class="quantity-text">共{{ detail.dishCount }}份菜品</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'orderCard',
  props: {
    detail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {};
  },
  computed: {
    totalQuantity() {
      if (Array.isArray(this.detail.orderDetailList)) {
        return this.detail.orderDetailList.map(item => +item.quantity).reduce((prev, next) => prev + next, 0);
      } else {
        return '-';
      }
    }
  },
  created() {},
  methods: {
    cardOnClick() {
      this.$router.push({
        name: 'eldCanOrderDetail',
        query: {
          id: this.detail.id
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.order-card {
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 4px 16px 0px rgba(218, 216, 216, 0.5);
  .card-title {
    box-sizing: border-box;
    height: 82px;
    border-bottom: 1px solid #e9e9e9;
    padding: 0px 24px;
    color: #151c39;
    font-size: 28px;
    line-height: 32px;
    display: flex;
    align-items: center;
  }
  .card-content {
    box-sizing: border-box;
    padding: 22px 20px 22px 34px;
    display: flex;
    align-items: center;
    width: 100%;
    .label-value-list {
      box-sizing: border-box;
      flex-grow: 1;
      width: calc(100% - 200px);
      .label-value-item {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        align-items: center;
        font-size: 28px;
        line-height: 32px;
        color: #151c39;
        margin-bottom: 28px;
        .label-icon {
          box-sizing: border-box;
          width: 28px;
          height: 28px;
          flex-shrink: 0;
          margin-right: 12px;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: 100% 100%;
          &.dcr {
            background-image: url('../img/label_icon_01.png');
          }
          &.scdz {
            background-image: url('../img/label_icon_02.png');
          }
          &.pssj {
            background-image: url('../img/label_icon_03.png');
          }
        }
        .label-text {
          box-sizing: border-box;
          flex-shrink: 0;
          width: 146px;
        }
        .value-text {
          box-sizing: border-box;
          width: calc(100% - 186px);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #007eff;
        }
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
    .price-quantity-info {
      box-sizing: border-box;
      flex-shrink: 0;
      text-align: center;
      margin-left: 10px;
      .price-text {
        box-sizing: border-box;
        font-size: 28px;
        line-height: 32px;
        color: #151c39;
        font-weight: 700;
      }
      .quantity-text {
        box-sizing: border-box;
        font-size: 24px;
        line-height: 28px;
        color: #666666;
        margin-top: 20px;
      }
    }
  }
}
</style>
